// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root .hXTH8.ui.dropdown.button{flex-grow:0}:root .hXTH8.ui.dropdown.button .menu{border:none}:root .hXTH8.ui.dropdown.button .uQYP_{background-color:#2185d0;color:#fff;font-size:.9em}:root .hXTH8.ui.dropdown.button .uQYP_:hover{background-color:#1678c2}:root .thcX9{font-size:14.4px;font-size:.9rem;max-height:160px;max-height:10rem;overflow-x:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `hXTH8`,
	"menuItem": `uQYP_`,
	"error": `thcX9`
};
module.exports = ___CSS_LOADER_EXPORT___;
