import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useEffect, useState, useRef } from 'react';
import type { StrictFormProps } from 'semantic-ui-react';
import { Button, Form } from 'semantic-ui-react';

import type { RbacManagerPermissionList } from '@/api/api';
import history from '@/history';
import { login } from '@redux/actions/action-auth';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import raiseToast from '@shared/components/Toast';

import type { AuthType } from '../useAuthType';

import LoginFormErrors, { createAuthResponseError } from './LoginFormErrors';
import type { ValidationResult } from './validateFields';
import validateFields from './validateFields';
import s from './LoginForm.scss';

export type FormAuthType = Exclude<AuthType, 'sso'>;

export interface LoginFormProps {
  /**
   * The actions the user is allowed to perform at the destination route.
   */
  actions?: RbacManagerPermissionList;
  /**
   * The currently selected authentication method.
   */
  authType?: FormAuthType;
  /**
   * Callback when the authentication lock state changes.
   */
  onAuthLockChange?: (isLocked: boolean) => void;
  /**
   * Callback when the form has validation errors.
   */
  onError?: (errors: ValidationResult[]) => void;
}

/**
 * Renders a form for username-password authentication. Maintains consistent
 * behavior for all relevant auth types (i.e. native, LDAP).
 */
const LoginForm = ({
  actions = [],
  authType = 'default',
  onAuthLockChange,
  onError,
}: LoginFormProps) => {
  const dispatch = useAppDispatch();
  const isFetchingAuth = useAppSelector(state => state.auth.isFetching);
  const hasAuthError = useAppSelector(state => state.auth.loginError);
  const authError = useAppSelector(state => state.auth.errorMsg);

  const [errors, setErrors] = useState<ValidationResult[]>([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  /**
   * Assign the input refs on mount.
   *
   * This is a temporary workaround. Semantic-ui-react v2 doesn't provide proper
   * support for refs (coming in v3).
   */
  useEffect(() => {
    if (!usernameRef.current) {
      // @ts-expect-error -- Refs are mutable and this is a valid use case
      usernameRef.current = document.getElementById(
        'anchore-login-form-username',
      ) as HTMLInputElement;
    }

    if (!passwordRef.current) {
      // @ts-expect-error -- Refs are mutable and this is a valid use case
      passwordRef.current = document.getElementById(
        'anchore-login-form-password',
      ) as HTMLInputElement;
    }
  }, []);

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  /**
   * Handles form submission. Credentials are collected from the form fields,
   * validated, and passed on to the server.
   */
  const handleSubmit: StrictFormProps['onSubmit'] = async event => {
    event.preventDefault();
    raiseToast(false);

    // Trim any surrounding whitespace prior to submission
    const username = usernameRef.current?.value.trim() || '';
    const password = passwordRef.current?.value.trim() || '';

    const validationResults = validateFields([
      { type: 'username', value: username },
      { type: 'password', value: password },
    ]);

    const validationErrors = validationResults.filter(item => item.error);

    // Always update errors so that previous errors might be cleared
    setErrors(validationErrors);

    // Stop processing if there are new errors
    if (validationErrors.length) return;

    const loginResponse = await dispatch(
      login({ username, password, actions, authType }),
    );

    if (loginResponse.type === 'SET_LOGIN_ERROR') {
      passwordRef.current?.focus();
      return;
    }

    if (
      !loginResponse.isRequestedContextValid &&
      !loginResponse.isRequestedContextActuallyComponentPath
    ) {
      // If the user attempted to log in using a context that is not permitted
      // then we need to raise a toast
      //
      // If the user entered `/<invalid_context>/<component_path>` we replace
      // the first path token with the default account context and redirect.
      // Note that if they only enter `/<invalid_context>` we let the
      // redirection logic in `base.js` handle the redirect (although we still
      // raise a toast).
      if (!loginResponse.wasOnlyContextPathRequested) {
        const pathnameArr = loginResponse.requestedPath.split('/');
        pathnameArr[1] = loginResponse.account.accountname;
        history.push(pathnameArr.join('/'));
      }

      raiseToast({
        toastId: `account_context_unavailable`,
        message:
          'Unfortunately, you attempted to authenticate against an account context for which you do not have access. You have been redirected to your default account context.',
        level: 'error',
        autoClose: 8000,
      });
    } else if (
      loginResponse.isRequestedContextActuallyComponentPath &&
      !loginResponse.isRequestedContextValid
    ) {
      // If the user attempted to log in using a context that is actually a
      // component path, we add the default context to the path
      history.push(
        `/${loginResponse.account.accountname}${loginResponse.requestedPath}`,
      );
    }
  };

  useEffect(() => {
    if (hasAuthError) {
      // Consume auth response errors so they can be displayed
      setErrors([createAuthResponseError(authError)]);
      return;
    }

    // Clear errors when the auth error is reset
    setErrors([]);
  }, [hasAuthError, authError]);

  useEffect(() => {
    // Update parents when errors change
    onError?.(errors);

    if (!errors.length) return;

    const [firstError] = errors;

    // Focus the first invalid field when there are new errors
    switch (firstError.type) {
      case 'username':
        usernameRef.current?.focus();
        break;

      case 'password':
        passwordRef.current?.focus();
        break;

      default:
        break;
    }
  }, [errors]);

  const hasUsernameError = errors.some(item => item.type === 'username');
  const hasPasswordError = errors.some(item => item.type === 'password');

  return (
    <Form
      className={classNames({
        'animate__animated animate__fadeIn': true,
        [s.form]: true,
        [s.muted]: isFetchingAuth,
      })}
      id="anchore-login-form"
      size="large"
      aria-label="Anchore Login"
      onSubmit={handleSubmit}
      error={!!errors.length}
      noValidate
    >
      <LoginFormErrors errors={errors} onAuthLockChange={onAuthLockChange} />

      <Form.Input
        id="anchore-login-form-username"
        name="username"
        label="Username"
        icon="user"
        iconPosition="left"
        type="text"
        disabled={isFetchingAuth}
        error={hasUsernameError}
        maxLength={100}
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        autoFocus
        required
      />

      <Form.Input
        action={{
          'aria-label': `${isPasswordVisible ? 'Hide' : 'Show'} password`,
          type: 'button',
          icon: isPasswordVisible ? 'eye slash' : 'eye',
          onClick: () => {
            togglePasswordVisibility();
            passwordRef.current?.focus();
          },
        }}
        id="anchore-login-form-password"
        name="password"
        label="Password"
        icon="lock"
        iconPosition="left"
        type={isPasswordVisible ? 'string' : 'password'}
        disabled={isFetchingAuth}
        error={hasPasswordError}
        maxLength={100}
        autoComplete="off"
        required
      />

      <Button
        type="submit"
        id="anchore-login-form-submit"
        size="large"
        icon="sign in"
        content="Continue to Anchore"
        primary={!isFetchingAuth}
        disabled={isFetchingAuth}
        loading={isFetchingAuth}
        fluid
      />
    </Form>
  );
};

export default withStyles(s)(LoginForm);
