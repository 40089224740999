import { useLocalStorage } from 'usehooks-ts';

import type { AuthType } from '@components/Login/useAuthType';

export interface StoredAuthPreferences {
  /**
   * The user's preferred authentication type.
   */
  authType: AuthType | null;
  /**
   * The user's preferred identity provider.
   */
  provider: string | null;
}

/**
 * Convenience hook to manage a given user's auth preferences, persisted to
 * local storage.
 *
 * The underlying hook provides a React-friendly interface for local storage,
 * and supports multiple consumers of the same key, so all receive updates.
 */
const useAuthPreferences = () => {
  const [authPreferences, setAuthPreferences] =
    useLocalStorage<StoredAuthPreferences>('anchore:authPreferences', {
      authType: null,
      provider: null,
    });

  const setPreferredAuthType = (value: AuthType) => {
    setAuthPreferences(previous => ({
      ...previous,
      authType: value,
    }));
  };

  const setPreferredProvider = (value: string) => {
    setAuthPreferences(previous => ({
      ...previous,
      provider: value,
    }));
  };

  const helpers = {
    setPreferredAuthType,
    setPreferredProvider,
  };

  return [authPreferences, setAuthPreferences, helpers] as const;
};

export default useAuthPreferences;
