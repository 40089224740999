import withStyles from 'isomorphic-style-loader/withStyles';
import { useMemo } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Message } from 'semantic-ui-react';

import { resetState } from '@redux/actions/action-auth';
import { useAppSelector, useAppDispatch } from '@redux/hooks';

import type { ValidationResult } from './validateFields';
import s from './LoginFormErrors.scss';

export interface LoginFormErrorsProps {
  /**
   * List of validation errors to display.
   */
  errors?: ValidationResult[];
  /**
   * Callback when the authentication lock state changes.
   */
  onAuthLockChange?: (isLocked: boolean) => void;
}

// Captures remaining lockout time from an error message (if present)
export const AUTH_LOCK_REGEX = /{(\d*)}/g;

export const createAuthResponseError = (error: any): ValidationResult => ({
  type: 'response',
  error: error?.message?.replace(AUTH_LOCK_REGEX, '') as string,
});

/**
 * Renders a list of validation errors when a login attempt fails.
 */
const LoginFormErrors = ({
  errors = [],
  onAuthLockChange,
}: LoginFormErrorsProps) => {
  const dispatch = useAppDispatch();
  const isFetchingAuth = useAppSelector(state => state.auth.isFetching);
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);
  const authError = useAppSelector(state => state.auth.errorMsg);

  /**
   * Extracts the lockout time from an auth error message.
   */
  const authLockDuration = useMemo(() => {
    if (authError?.statusCode !== 423) return null;

    const message = authError?.message || '';
    const [, lockoutTime] = AUTH_LOCK_REGEX.exec(message) ?? [];

    if (typeof lockoutTime === 'string' && lockoutTime.length) {
      onAuthLockChange?.(true);
      return parseInt(lockoutTime, 10);
    }

    return null;
  }, [authError]);

  const getMessages = () =>
    errors.map(item => (
      <Message.Item key={item.type}>
        <div className={s.messageWrapper}>
          <span>{item.error}</span>

          {authLockDuration ? (
            <div className={s.countdownWrapper}>
              <CountdownCircleTimer
                size={50}
                strokeWidth={4}
                strokeLinecap="square"
                isPlaying
                duration={authLockDuration}
                colors={['#DB2828', '#DB2828', '#21BA45', '#21BA45']}
                colorsTime={[
                  authLockDuration,
                  Math.round(authLockDuration * 0.2),
                  Math.round(authLockDuration * 0.1),
                  0,
                ]}
                onComplete={() => {
                  if (!isAuthenticated && !isFetchingAuth) {
                    onAuthLockChange?.(false);
                    dispatch(
                      resetState({
                        auth: {
                          isAuthenticated: false,
                          loginError: false,
                          errorMsg: null,
                        },
                      }),
                    );
                  }
                }}
              >
                {({ remainingTime }) => (
                  <span className={s.countdownRemaining}>{remainingTime}</span>
                )}
              </CountdownCircleTimer>
            </div>
          ) : null}
        </div>
      </Message.Item>
    ));

  if (isFetchingAuth || !errors?.length) return null;

  return (
    <>
      <Message
        className="animate__animated animate__fadeIn"
        size="small"
        list={getMessages()}
        error
      />
    </>
  );
};

export default withStyles(s)(LoginFormErrors);
