import type { ReactNode } from 'react';
import validator from 'validator';

export type LoginFieldName = 'username' | 'password';

export interface ValidationResult {
  type: LoginFieldName | 'response';
  error: boolean | ReactNode;
}

export interface FieldToValidate {
  type: LoginFieldName;
  value: string;
}

const validateField = ({ value, type }: FieldToValidate) => {
  const result: ValidationResult = {
    type,
    error: false,
  };

  switch (type) {
    case 'username':
      if (validator.isEmpty(value))
        result.error = (
          <span>
            You must enter a <strong>username</strong>
          </span>
        );

      if (value === '_api_key')
        result.error = (
          <span>You cannot log into the application using an API key</span>
        );
      break;

    case 'password':
      if (validator.isEmpty(value))
        result.error = (
          <span>
            You must enter a <strong>password</strong>
          </span>
        );
      break;

    default:
      result.error = 'No validation criteria were found for this property';
  }

  return result;
};

/**
 * Validates login form field values and returns an array of validation results.
 */
const validateFields = (fields: FieldToValidate[]) =>
  fields.map(item => validateField(item));

export default validateFields;
