import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import moment from 'moment-timezone';
import type { MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import type { TabProps } from 'semantic-ui-react';
import { Divider, Grid, Header, Loader, Tab, TabPane } from 'semantic-ui-react';

import type { RbacManagerPermissionList } from '@/api/api';
import { useAppSelector } from '@redux/hooks';
import useAuthType from '@components/Login/useAuthType';
import useSSO from '@components/Login/useSSO';

import CustomLinks from './CustomLinks';
import CustomMessage from './CustomMessage';
import LoginForm from './LoginForm';
import LoginWithSSO from './LoginWithSSO';
import s from './Login.scss';

const authTypeContentMap = {
  default: {
    label: 'Default',
    icon: 'database',
    getDescription: () => 'Authenticate using your Anchore credentials',
  },
  ldap: {
    label: 'LDAP',
    icon: 'sitemap',
    getDescription: () => 'Authenticate using your LDAP credentials',
  },
  sso: {
    label: 'SSO',
    icon: 'cloud',
    getDescription: (pluralize?: boolean) =>
      `Authenticate using${pluralize ? ' one of' : ''} your identity provider${pluralize ? 's' : ''}`,
  },
};

export interface LoginProps {
  /**
   * The actions the user is allowed to perform at the destination route.
   */
  actions?: RbacManagerPermissionList;
  /**
   * Flag to determine whether LDAP authentication is enabled.
   */
  isLdapEnabled?: boolean;
  /**
   * Restricts login to SSO only. Other auth types will not be presented to the
   * user.
   */
  isSsoAuthOnly?: boolean;
}

/**
 * The Login component presents unauthenticated users with one or more methods
 * to authenticate, based on the app configuration.
 */
const Login = ({
  actions = [],
  isLdapEnabled = false,
  isSsoAuthOnly = false,
}: LoginProps) => {
  const [canRender, setCanRender] = useState<boolean>(() => {
    if (!process.env.BROWSER) return false;

    if (!window.App.isReady) {
      window.onload = () => {
        setTimeout(() => {
          window.App.isReady = true;
          setCanRender(true);
        }, 0);
      };
    }

    return window.App.isReady || false;
  });

  // Locked/error states from child components are captured here for visual side effects
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const sso = useSSO({ isSsoAuthOnly });

  const [authType, setAuthType, enabledAuthTypes] = useAuthType({
    sso,
    isLdapEnabled,
    isSsoAuthOnly,
  });

  const isFetchingAuth = useAppSelector(state => state.auth.isFetching);

  /**
   * `enabledAuthTypes` is the most accurate source of configured and available
   * auth types, so we only rebuild auth panes when it changes. Enabled types
   * are stable and unlikely to change after resolving the SSO providers.
   */
  const authPanes = useMemo(
    () =>
      enabledAuthTypes.map(enabledAuthType => {
        const content =
          enabledAuthType === 'sso' ? (
            <LoginWithSSO providers={sso.providers} error={sso.error} />
          ) : (
            <LoginForm
              actions={actions}
              authType={enabledAuthType}
              onAuthLockChange={value => setIsLocked(value)}
              onError={errors => setHasErrors(!!errors.length)}
            />
          );

        const { label, getDescription, icon } =
          authTypeContentMap[enabledAuthType];

        return {
          // Include the auth type to read in the tab change handler
          authType: enabledAuthType,
          menuItem: {
            icon,
            key: enabledAuthType,
            content: label,
            as: 'button',
            role: 'tab',
          },
          render: () => (
            <TabPane role="tabpanel" padded="very" attached={false}>
              <Header
                as="h1"
                size="large"
                textAlign="center"
                content={`Log in ${enabledAuthType !== 'default' ? `with ${label}` : ''}`}
                subheader={getDescription(sso.providers.length > 1)}
              />
              <Divider />
              {content}
            </TabPane>
          ),
        };
      }),
    [enabledAuthTypes],
  );

  /**
   * Sync the active tab index as a side effect of the auth type changing. This
   * avoids having another explicit state setter. The state we are actually
   * interested in is `authType`, so `activeIndex` is just a pass-through state
   * to interface with the Tab component.
   */
  const activeIndex = useMemo(
    () => authPanes.findIndex(tabPane => tabPane.authType === authType),
    [authType, authPanes],
  );

  /**
   * Updates the auth type when the active tab changes. The active tab is an
   * index, so we extract the auth type from custom pane data that was assigned
   * on init.
   */
  const handleTabChange = (_: MouseEvent, data: TabProps) => {
    if (data.activeIndex === undefined) return;

    setAuthType(authPanes[data.activeIndex].authType);
  };

  const hasMultipleTabs = authPanes.length > 1;

  const [authPane] = authPanes;

  return (
    <div
      className={classNames({
        [s.login]: true,
        hasMultipleTabs,
      })}
    >
      <Grid centered verticalAlign="middle" className={s.grid}>
        <Grid.Column
          mobile={12}
          tablet={8}
          computer={6}
          largeScreen={5}
          widescreen={4}
          style={{
            zIndex: 101,
          }}
        >
          {canRender && !sso.isFetching && !!authPanes.length ? (
            <>
              <div
                className={classNames({
                  darkSVG: true,
                  [s.sadRobot]: true,
                  [s.grr]: hasErrors && !isFetchingAuth,
                  [s.dec]: moment().month() === 11,
                  [s.locked]: isLocked,
                })}
              />

              {hasMultipleTabs ? (
                <Tab
                  role="tablist"
                  className={s.tabs}
                  activeIndex={activeIndex}
                  menu={{ secondary: true }}
                  panes={authPanes}
                  onTabChange={handleTabChange}
                />
              ) : (
                authPane.render()
              )}

              <CustomLinks />

              <CustomMessage />
            </>
          ) : (
            <Loader size="massive" active inline="centered" />
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default withStyles(s)(Login);
