// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root .Cxwz9 .eEbqU{box-shadow:none;width:100%!important}:root .Cxwz9 .eEbqU .FUYXw{align-items:center!important;display:flex!important;justify-content:space-between!important;line-height:20px!important;line-height:1.25rem!important}:root .Cxwz9 .eEbqU .FUYXw .JBTy8.menu{box-shadow:none}:root .Cxwz9 .eEbqU .FUYXw .KhUxo{display:flex!important;justify-content:space-between!important}:root .Cxwz9 .eEbqU .FUYXw .KhUxo .GWhxA{color:#4183c4;max-width:400px;max-width:25rem;overflow:hidden;text-overflow:ellipsis}:root .Cxwz9 .eEbqU .FUYXw .KhUxo .GWhxA:hover{color:#1e70bf}:root .Cxwz9 .eEbqU .FUYXw .KhUxo .Zn7s0{position:relative;right:-12px;right:-.75rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"segment": `Cxwz9`,
	"menu": `eEbqU`,
	"dropdown": `FUYXw`,
	"dropdownMenu": `JBTy8`,
	"dropdownItem": `KhUxo`,
	"dropdownItemTitle": `GWhxA`,
	"dropdownItemIcon": `Zn7s0`
};
module.exports = ___CSS_LOADER_EXPORT___;
