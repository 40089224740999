import Layout from '@components/Layout';
import Login from '@components/Login';

import arePermissionsGoodForRoute from './arePermsGoodForRoute';

export default Component => props => {
  const {
    isAuthenticated,
    isEngineVersionGood,
    dbVersion,
    engineVersion,
    uiVersion,
    isEngineHealthy,
    isLicenseGood,
    route,
    params,
    account,
    isReportsEnabledAndActive,
    getUserPerms,
    isLdapEnabled,
    isSsoAuthOnly,
    isAppDBHealthy,
    pathname,
    apolloClient,
    apiUrl,
    query,
    customLinks,
    baseUrl,
    whatsNew,
    isWebSocketOnly,
    enrichInventoryView,
  } = props;

  const { parent, path } = route;
  const componentContext = !parent?.path ? route : parent;
  const { actions, navbar, redirect } = componentContext;
  const { title, name } = navbar;

  return getUserPerms({ account, actions })
    .then(permissions => ({
      chunks: [name],
      title,
      component: (
        <Layout
          isEngineVersionGood={isEngineVersionGood}
          isAuthenticated={isAuthenticated}
          dbVersion={dbVersion}
          engineVersion={engineVersion}
          uiVersion={uiVersion}
          isEngineHealthy={isEngineHealthy}
          isLicenseGood={isLicenseGood}
          isReportsEnabledAndActive={isReportsEnabledAndActive}
          account={account}
          permissions={isAuthenticated ? permissions : false}
          isAppDBHealthy={isAppDBHealthy}
          apolloClient={apolloClient}
          pathname={pathname}
          apiUrl={apiUrl}
          customLinks={customLinks}
          routeMask={baseUrl + path}
          whatsNew={whatsNew}
          isWebSocketOnly={isWebSocketOnly}
          enrichInventoryView={enrichInventoryView}
        >
          <Component
            title={title}
            params={params}
            pathname={pathname}
            baseUrl={baseUrl}
            redirect={redirect}
            query={query}
            isAuthenticated={isAuthenticated}
            permissions={permissions}
            routePermission={
              isAuthenticated
                ? arePermissionsGoodForRoute({ pathname, permissions })
                : false
            }
            enrichInventoryView={enrichInventoryView}
          >
            <Login
              actions={actions}
              isLdapEnabled={isLdapEnabled}
              isSsoAuthOnly={isSsoAuthOnly}
            />
          </Component>
        </Layout>
      ),
    }))
    .catch(err => {
      throw err;
    });
};
