import withStyles from 'isomorphic-style-loader/withStyles';
import MarkdownIt from 'markdown-it';
import { useState } from 'react';
import { Segment } from 'semantic-ui-react';

import s from './CustomMessage.scss';

const md = MarkdownIt({ linkify: true, typographer: true });

/**
 * Renders a custom message, if supplied in config.
 *
 * The message can be supplied as markdown, which is transformed to HTML.
 */
const CustomMessage = () => {
  const [content] = useState(
    process.env.BROWSER ? window.App.customMessage : false,
  );

  if (!content) {
    return null;
  }

  const title = content.title ? (
    <h4
      className={s.title}
      // eslint-disable-next-line react/no-danger -- Alas, danger is required
      dangerouslySetInnerHTML={{
        __html: md.renderInline(content.title),
      }}
    />
  ) : null;

  const message = content.message ? (
    <div
      className={s.message}
      // eslint-disable-next-line react/no-danger -- Alas, danger is required
      dangerouslySetInnerHTML={{
        __html: md.render(content.message),
      }}
    />
  ) : null;

  return (
    <Segment.Group>
      <Segment className={s.segment}>
        {title}
        {message}
      </Segment>
    </Segment.Group>
  );
};

export default withStyles(s)(CustomMessage);
