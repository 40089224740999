import withStyles from 'isomorphic-style-loader/withStyles';
import { useState } from 'react';
import { Dropdown, Icon, Menu, Segment } from 'semantic-ui-react';

import { useAppSelector } from '@redux/hooks';

import s from './CustomLinks.scss';

/**
 * Renders a dropdown menu containing custom links, if supplied in config.
 */
const CustomLinks = () => {
  const isFetchingAuth = useAppSelector(state => state.auth.isFetching);

  const [content] = useState(
    process.env.BROWSER ? window.App.customLinks : false,
  );

  if (!content) {
    return null;
  }

  return (
    <Segment className={s.segment}>
      <Menu vertical className={s.menu}>
        <Dropdown
          scrolling
          disabled={isFetchingAuth}
          item
          text={content.title}
          aria-label={content.title}
          className={s.dropdown}
        >
          <Dropdown.Menu className={s.dropdownMenu}>
            {content.links.map(({ title, uri }) => (
              <Dropdown.Item
                className={s.dropdownItem}
                as="a"
                rel="noopener noreferrer"
                target="_blank"
                href={uri}
                key={`item.title_${Math.random()}`}
              >
                <span className={s.dropdownItemTitle}>{title}</span>
                <Icon
                  className={s.dropdownItemIcon}
                  color="blue"
                  fitted
                  name="external alternate"
                />
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    </Segment>
  );
};

export default withStyles(s)(CustomLinks);
