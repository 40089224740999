// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ae_WH{clear:both;margin:0;z-index:1}.K33Cb{border-bottom:1px solid #dededf;padding-bottom:.9rem}.qMXw9{margin-bottom:.5rem;margin-top:.5rem;max-height:160px;max-height:10rem;overflow-x:hidden}.qMXw9 img{width:100%}.qMXw9 blockquote{border-left:.25rem solid #dededf;margin:0 0 1rem;padding:.5rem 1rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"segment": `ae_WH`,
	"title": `K33Cb`,
	"message": `qMXw9`
};
module.exports = ___CSS_LOADER_EXPORT___;
