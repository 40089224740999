import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { useMemo } from 'react';
import type { ButtonProps, DropdownItemProps } from 'semantic-ui-react';
import { Message, Button, Dropdown, Icon } from 'semantic-ui-react';

import { useAppSelector } from '@redux/hooks';

import useAuthPreferences from '../useAuthPreferences';

import s from './LoginWithSSO.scss';

export interface LoginWithSSOProps {
  /**
   * List of configured identity providers that the user can authenticate with.
   */
  providers?: string[];
  /**
   * Error message to display if there was a problem obtaining the list of
   * identity providers.
   */
  error?: string;
}

/**
 * Renders a list of identity providers that the user can authenticate with, if
 * configured.
 */
const LoginWithSSO = ({ providers = [], error }: LoginWithSSOProps) => {
  const [authPreferences, , { setPreferredProvider }] = useAuthPreferences();

  const isFetchingAuth = useAppSelector(state => state.auth.isFetching);

  const [primaryProvider, ...otherProviders] = useMemo(() => {
    const relayState = window.btoa(document.location.pathname);

    return providers.reduce<DropdownItemProps[]>(
      (menuItems, provider, currentIndex) => {
        // Primary provider falls back to the first item unless the user has a stored preference
        const isPrimary =
          authPreferences.provider === provider || currentIndex === 0;

        const encodedProvider = encodeURIComponent(provider);

        const itemProps = {
          key: provider,
          className: s.menuItem,
          as: 'a',
          icon: 'key',
          href: `/service/sso/redirect/${encodedProvider}?RelayState=${relayState}`,
          content: (
            <>
              Continue with <strong>{provider}</strong>
            </>
          ),
          // Persist the selected provider so that it is marked as primary next time
          onClick: () => setPreferredProvider(provider),
        };

        // The first item is assumed to be the primary IDP and rendered as the default button action
        if (isPrimary) {
          return [itemProps, ...menuItems];
        }

        // Secondary IDPs are rendered into a nested menu
        return [...menuItems, itemProps];
      },
      [],
    );
  }, [providers, authPreferences.provider]);

  if (
    !process.env.BROWSER ||
    isFetchingAuth ||
    (!providers?.length && !error)
  ) {
    return null;
  }

  if (error) {
    return (
      <Message
        className={classNames(s.error, 'animate__animated animate__fadeIn')}
        size="small"
        error
      >
        <Icon name="warning sign" />
        {error} Please contact your administrator.
      </Message>
    );
  }

  return (
    <Button.Group color="blue" size="large" primary fluid>
      <Button {...(primaryProvider as ButtonProps)} role="link" />

      {!!otherProviders.length && (
        <Dropdown
          className={classNames('button icon', s.dropdown)}
          aria-label={`${otherProviders.length} More...`}
          options={otherProviders}
          trigger={<></>}
          floating
          scrolling
        />
      )}
    </Button.Group>
  );
};

export default withStyles(s)(LoginWithSSO);
